import * as React from "react";
import { Header, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const FrequentlyAskedQuestions = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="question circle" />
          <Header.Content>
            Frequently Asked Questions
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical>
        <ul>
          <li className="faq">What Host Platforms are currently supported?
            <ul>
              <li>Currently only Linux (x86) and Windows. The source code is included, but it often fails to compile on 64-bit machines. 32-bit binaries are included for Linux and Windows, which will run on 64-bit machines. We have a tentative patch for 64-bit machines, and we will include it at a later version.</li>
            </ul>
          </li>
          <li className="faq">What target platforms are currently supported?
            <ul>
              <li>In version 0.1 of the TinyOS UnStacked C, only MSP platforms are currently supported. The compiler itself supports AVR platforms, but the build scripts have not yet been updated. If you update the build script to support AVRs, then send the patch out way and we'll include it in the distribution.</li>
            </ul>
          </li>
          <li className="faq">What do all these warnings about "declared 'static' but never defined' mean?
            <ul >
              <li>These warnings can be safely ignored. Currently, we generate several prototypes of functions which are never called, so we do not generate the function bodies on the other side of our compiler. It is just a warning, and it will be fixed in a later release.</li>
            </ul>
          </li>
          <li className="faq">How many threads can a UnStacked C support?
            <ul >
              <li>We have scaled UnStacked C to tens of millions of threads. We'll put those results up when we put up the PC based development setup.</li>
            </ul>
          </li>
          <li  className="faq">What if I have not found an answer to my support questions?
            <ul>
              <li>Email <a href={"mailto:billmccartney@gmail.com"}>Bill McCartney</a></li>
            </ul>
          </li>
        </ul>
      </Segment>
    </Container>
  );
};

export default withLayout(FrequentlyAskedQuestions);
